import React, { memo } from 'react';
import { SearchDesktop, SearchMobile } from './parts';
import SearchIcon from '../../../public/images/icons/search-thin.svg';
import theme from '../../theme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => {
	return {
		search: {
			justifySelf: 'flex-start',
			width: 31,
			padding: 5,
			margin: 'auto',
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		}
	}
}, {name: 'search'});

function Search({ center = false, show = true, emitShow, searchFocus, toggle }) {
	const classes = useStyles();
	function handleShow(event) {
		emitShow(event, show);
	}

	return (
		<>
			<SearchMobile show={show} emitShowMobile={handleShow} searchFocus={searchFocus} />
			<SearchDesktop center={center} show={show} />
			<SearchIcon className={classes.search} width={20} onClick={toggle}/>
		</>
	);
}

export default memo(Search);
