import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from '../../../tools/i18n';
import { useRouter } from 'next/router';

const useStyles = makeStyles(
    () => ({
        ctaButton: {
            position: 'relative',
            ...theme.button.purchase,
            flexGrow: 0,
            width: '100%',
            padding: '16px 8px',
            fontSize: 12,
            color: theme.palette.primary.background,
            height: 'fit-content',
            [theme.breakpoints.up('sm')]: {
                padding: '20px 8px',
                letterSpacing: 2,
            },
            '&.small': {
                padding: '12px 14px',
                letterSpacing: '2px',
                fontSize: '10px',
            },
            '&.bg-standard': {
                backgroundColor: '#041e3a',
                color: 'white',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#01011d',
                },
            },
            '&.bg-light': {
                backgroundColor: '#eaeff2',
                color: '#333',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#f7f9fa',
                },
            },
            '&.bg-wedding': {
                backgroundColor: '#a39161',
                color: 'white',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#c2af7b',
                },
            },
            '&.bg-wedding-fresh': {
                backgroundColor: '#DFF2F0',
                color: '#333',
                '&:after, &:before': {
                    backgroundColor: 'white',
                },
                '&:hover': {
                    backgroundColor: '#DFF2F0',
                    opacity: 0.8,
                },
            },
            '&.bg-white': {
                backgroundColor: 'white',
                color: 'black',
                '&:after, &:before': {
                    backgroundColor: 'black',
                },
                '&:hover': {
                    backgroundColor: '#ffffff',
                },
            },
            '&.remove-lines': {
                '&:after, &:before': {
                    display: 'none',
                    backgroundColor: 'transparent',
                },
            },
            '&:before': {
                content: '""',
                height: 1,
                backgroundColor: theme.button.purchasePsuedo.background,
                display: 'block',
                position: 'absolute',
                top: 4,
                left: 0,
                right: 0,
                transition: 'all 0.25s ease',
            },
            '&:after': {
                content: '""',
                height: 1,
                backgroundColor: theme.button.purchasePsuedo.background,
                display: 'block',
                position: 'absolute',
                bottom: 4,
                left: 0,
                right: 0,
                transition: 'all 0.25s ease',
            },
            '&:hover': {
                backgroundColor: theme.button.purchaseHover.backgroundColor,
                '&:before, &:after': {
                    left: 4,
                    right: 4,
                },
            },
        },
        label: {
            flexDirection: 'column',
        },
        labelRightAlign: {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        labelLeftAlign: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        ctaButtonDisabled: {
            color: `${theme.button.purchaseDisabled.color} !important`,
            opacity: 0.4,
        },
        soldOut: {
            width: '100%',
            backgroundColor: theme.button.purchaseDisabled.backgroundColor,
            '&:hover': {
                backgroundColor: theme.button.purchaseDisabled.backgroundColor,
            },
            '&.success': {
                backgroundColor: '#0e6853',
            },
        },
        weddingListAdd: {
            maxHeight: 58,
            marginBottom: 10,
            backgroundColor: '#a39161',
        },
        spinnyThing: {
            position: 'absolute',
            right: '1rem',
            '&.small': {
                transform: 'scale(0.18)',
                right: '0.5rem',
            },
        },
    }),
    { name: 'cta-button' },
);

/**
 * @param onClick
 * @param disabled
 * @param children
 * @param {'small'|'normal'} size
 * @param {('bg-white'|'bg-wedding'|'bg-wedding-fresh'|'bg-standard'|'bg-light')} colorScheme
 * @param removeLines
 * @param {boolean} isLoading
 * @param {object} style
 * @param {boolean} show
 * @param href
 * @param props
 */
export default function CallToActionButton(
    {
        onClick,
        disabled,
        children,
        size = 'normal',
        colorScheme = 'bg-standard',
        addLines = false,
        isLoading = false,
        style,
        show = true,
        href = null,
        labelPosition = null,
        className = null,
        ...props
    },
) {
    const classes = useStyles();
    const { t } = useTranslation([ 'general', 'popup', 'product-info' ]);
    const router = useRouter();
    const removeLinesClass = addLines ? null : 'remove-lines';

    if (!show) {
        return null;
    }

    function renderLoading(isLoading) {
        if (!isLoading) {
            return null;
        }

        return (
            <CircularProgress
                className={`${classes.spinnyThing} ${size} ${colorScheme}`}
                color={theme.palette.primary.background}
                size={18}
                style={{ marginLeft: 6 }}
            />
        );
    }

    function resolveLabelClass(labelPosition) {
        if (labelPosition === 'right') {
            return classes.labelRightAlign;
        }

        if (labelPosition === 'left') {
            return classes.labelLeftAlign;
        }

        return classes.label;
    }

    function middleware(component) {
        if (href) {
            const originalClick = onClick || function() {};
            onClick = async () => {
                await originalClick();
                await router.push(href);
            }

            return <a href={href} onClick={onClick}>{component}</a>;
        }

        return component;
    }

    return middleware(
        <Button
            {...props}
            style={style}
            className={[classes.ctaButton, size, colorScheme, removeLinesClass, className].join(' ')}
            onClick={onClick}
            classes={{
                label: resolveLabelClass(labelPosition),
                disabled: classes.ctaButtonDisabled,
            }}
            disabled={disabled}
        >
            {children}
            {renderLoading(isLoading)}
        </Button>
    );
}
