import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
import { useTranslation } from 'tools/i18n';

const useStyles = makeStyles(() => ({
    uspItem: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        marginTop: 0,
        fontSize: 12,
        fontWeight: 300,
        '& svg': {
            flexShrink: 0,
            width: 15,
            marginRight: 10,
            strokeWidth: 0.5,
        },
        '&:first-of-type': {
            marginTop: '0px !important',
        },
    },
    text: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        '&.in-stock > svg': {
            color: 'green',
        },
        '&.order-to-stock > svg': {
            color: 'inherit',
        },
        '& a': {
            color: 'inherit',
        },
        '&.wishlist': {
            cursor: 'pointer',
            marginBottom: 5,
            '& > svg': {
                fill: 'transparent',
            },
            '&.active > svg': {
                fill: '#000',
            },
            [theme.breakpoints.down('sm')]: {
                strokeWidth: 0.25,
            },
        },
    },
}), { name: 'UspItem' });

export default function UspItem(
    {
        text,
        icon,
        onClick = null,
        classNames = [],
        style = {},
    },
) {
    const classes = useStyles();
    const { t } = useTranslation();

    return <>
        <span style={style} className={[ classes.uspItem, classes.text, ...classNames ].join(' ')} onClick={onClick}>
            {icon}
            {t(text)}
        </span>
    </>;
}
