import { useTranslation } from 'tools/i18n';
import Like from '../../../../public/images/icons/heart-ultrathin.svg';
import useUserStore from '../../../../services/stores/UserStore';
import UspItem from '../UspItem';

export default function WishlistUspItem({ productId, style = null, classNames = [] }) {
    const { t } = useTranslation();
    const user = useUserStore();
    const isHighlighted = user.isHighlighted(productId);
    const active = isHighlighted ? 'active' : '';
    const text = isHighlighted ? t('is-in-wishlist') : t('add-to-wishlist');

    if (!user.isLoggedIn) {
        return null;
    }

    return <>
        <UspItem
            style={style}
            classNames={[ 'wishlist', active, ...classNames ]}
            onClick={() => user.addFavorite(productId)}
            text={text}
            icon={<Like width={20} height={20} alt={t('buy-short')} />}
        >
        </UspItem>
    </>;
}
